<template>
  <CRow>
    <CCol>
      <div class="table-controls">
        <div class="faded pl-2">
          {{ selected }}
          {{ selected === 1 ? 'item' : 'items' }}
          selected
        </div>
        <CDropdown
          v-if="selected"
          class="pl-2"
          :add-toggler-classes="{ 'btn-pill': true }"
          :caret="false"
        >
          <template #toggler-content>
            Action <CIcon name="md-expand-more" height="15" />
          </template>
          <CDropdownItem v-for="action in actions" :key="action.name" @click="$emit(action.name)">
            {{ action.text }}
          </CDropdownItem>
        </CDropdown>
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'TableActions',
  props: {
    selected: {
      type: Number,
      required: true
    },
    actions: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * Update the page size and refresh the table data.
     *
     * @param {Number} newPageSize
     */
    handleUpdatePageSize (newPageSize) {
      this.$emit('update:page-size', newPageSize)
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.faded {
  color: $gray-600;
}
.table-controls {
  display: flex;
  align-items: center;
  min-height: 35px;

  .dropdown-menu {
    .dropdown-item {
      padding: 0.1rem 1rem;
    }
  }
}
</style>
