<template>
  <div>
    <CRow class="mb-2">
      <CCol>
        <h2>Pending Recurring Zoho Invoices</h2>
        <span>The following customers are pending recurring invoices. Add the recurring invoice ID to remove them from this list.</span>
      </CCol>
    </CRow>
    <TableNotifications
      :errors="errors"
      :successes="successes"
      :texts="{ error: 'Error removing invoice: ' }"
    />
    <TableActions
      :selected="selected.length"
      :actions="[{ name: 'batch-remove', text: 'Remove Recurring Invoices' }]"
      @batch-remove="handleBatchRemove"
    />
    <InvoiceTableData
      :data="tableData"
      :loading="loading"
      @selection-change="selected = $event"
      @edit-property="editProperty($event)"
    />
    <PendingInvoicesAside
      :show.sync="showAside"
      :company="editTarget.company"
      :subscription="editTarget.subscription"
      :property="editTarget.property"
      :invoicing-group="editTarget.invoicingGroup"
      @handle-remove="handleRemove"
      @update:row="handleUpdate"
    />
  </div>
</template>

<script>
import { InvoicesAPI, RooofAPI } from '@/services/api/resources'
import { deepCopy } from '@/utils'

import InvoiceTableData from './_components/InvoiceTableData'
import PendingInvoicesAside from './_components/PendingInvoicesAside'
import TableActions from './_components/TableActions'
import TableNotifications from './_components/TableNotifications'

export default {
  name: 'PendingZohoInvoice',
  components: {
    PendingInvoicesAside,
    InvoiceTableData,
    TableActions,
    TableNotifications
  },
  data () {
    return {
      loading: false,
      tableData: [],
      selected: [],
      errors: [],
      successes: [],
      showAside: false,
      editTarget: {
        company: null,
        invoicingGroup: null,
        property: null,
        subscription: null
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    /**
     * Fetch the list of customers with pending
     * zoho recurring invoice.
     */
    async fetchData () {
      this.loading = true
      const subscriptionsData = await this.fetchSubscriptionsNeedingInvoiceSetup()
      this.tableData = await this.formatTableData(subscriptionsData)
      this.loading = false
    },
    /**
     * Fetch the list of product subscriptions which are pending
     * zoho recurring invoice setup
     */
    async fetchSubscriptionsNeedingInvoiceSetup () {
      const params = new URLSearchParams()
      params.append('status', 'active')
      params.append('status', 'onboarding')
      params.append('zoho_recurring_invoice__isnull', true)
      params.append('hide_from_recurring_invoices_report', false)
      params.append('rate__gt', 0)
      params.append('exclude_test_companies', true)
      const productResponse = await RooofAPI.productSubscriptions.list({ params })
      const featureResponse = await RooofAPI.featureSubscriptions.list({ params })
      if (!productResponse || !featureResponse) {
        return []
      }

      const subscriptionsData = productResponse.data.concat(featureResponse.data)
      return subscriptionsData
    },
    /**
     * Format the subscription data returned from the api
     * so it can be used in the table. Adds `company`
     * data.
     *
     * @param {Array} data - property data
     */
    async formatTableData (data) {
      return Promise.all(
        data.map(async (subscription) => {
          const company = (
            await RooofAPI.companies.retrieve({ id: subscription.property.company })
          ).data
          const invoicingGroup = (
            await InvoicesAPI.invoicingGroups.retrieve({ id: subscription.property.invoicing_group })
          ).data
          return {
            company,
            invoicingGroup,
            subscription
          }
        })
      )
    },
    /**
     * Send a request to mark recurring pending
     * invoice as done.
     *
     * @param {Number} propertyId
     * @returns {Promise}
     */
    async markInvoiceAsDone (row) {
      const subscriptionCopy = deepCopy(row.subscription)
      const property = deepCopy(row.subscription.property)
      subscriptionCopy.hide_from_recurring_invoices_report = true
      if (subscriptionCopy.product) {
        const index = property.product_subscriptions.findIndex(subscription => subscription.id === subscriptionCopy.id)
        property.product_subscriptions[index] = subscriptionCopy
      } else {
        const index = property.feature_subscriptions.findIndex(subscription => subscription.id === subscriptionCopy.id)
        property.feature_subscriptions[index] = subscriptionCopy
      }
      const config = {
        id: property.id,
        data: property
      }
      const response = await RooofAPI.properties.partialUpdate(config, false)
      return response
    },
    /**
     * Display the property form aside.
     *
     * @param {Object} row
     */
    editProperty (row) {
      this.editTarget.company = row.company
      this.editTarget.subscription = row.subscription
      this.editTarget.property = row.subscription.property
      this.editTarget.invoicingGroup = row.invoicingGroup
      this.showAside = true
    },
    /**
     * Remove a single zoho pending recurring invoice and update
     * table data.
     *
     * @param {Number} subscriptionId
     */
    async handleRemove (subscriptionId) {
      const index = this.tableData.findIndex(row => row.subscription.id === subscriptionId)
      this.errors = []
      this.successes = []
      const row = this.tableData[index]
      // Update hide_from_recurring_invoices_report to true
      try {
        await this.markInvoiceAsDone(row)
        row['property'] = { id: row.subscription.property.id, name: row.subscription.property.name }
        this.successes.push({ row })
        this.tableData.splice(index, 1)
      } catch (err) {
        this.errors.push({
          row, message: err.response.data
        })
      }
    },
    /**
     * Update the table row object post-save.
     *
     * @param {Object} data - new property data
     */
    handleUpdate (data) {
      const index = this.tableData.findIndex(row => row.subscription.id === data.subscription.id)
      const row = this.tableData[index]

      if (data.subscription.hide_from_recurring_invoices_report) {
        this.tableData.splice(index, 1)
      } else {
        this.$set(row, 'subscription', data.subscription)
        this.$set(row, 'company', data.company)
        this.$set(this.tableData, index, row)
      }

      this.$store.commit('notification/show', {
        message: `${row.subscription.property.name} saved`,
        type: 'success'
      })
    },
    /**
     * Send a request to API2 to "remove" invoice
     */
    async handleBatchRemove () {
      for (const row of this.selected) {
        await this.handleRemove(row.subscription.id)
      }
    }
  }
}
</script>
