<template>
  <CRow v-if="errors.length || warnings.length || successes.length">
    <CCol>
      <CRow>
        <CCol>
          <ul class="successes">
            <li v-for="success in successes" :key="success.row.id">
              <div>
                <b>{{ texts.success }}</b>
                {{ success.row.company.human_name }} - {{ success.row.property.name }}
              </div>
            </li>
          </ul>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <ul class="errors">
            <li v-for="error in errors" :key="error.row.id">
              <div>
                <b>{{ texts.error }}</b>
                {{ error.row.company.human_name }} - {{ error.row.property.name }}
              </div>
              <div>
                {{ error.message }}
              </div>
            </li>
          </ul>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <ul class="warnings">
            <li v-for="warning in warnings" :key="warning.row.id">
              <div>
                <b>{{ texts.warning }}</b>
                {{ warning.row.company.human_name }} - {{ warning.row.property.name }}
              </div>
              <div>
                {{ warning.message }}
              </div>
            </li>
          </ul>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'TableNotifications',
  props: {
    errors: {
      type: Array,
      default: () => []
    },
    warnings: {
      type: Array,
      default: () => []
    },
    successes: {
      type: Array,
      default: () => []
    },
    texts: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.errors, .warnings, .successes {
  padding-left: 0;

  li {
    list-style-type: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
}
.errors li {
  background-color: #FFECEC;
  border: 2px solid #F96060;
  color: #E54B4B;
}
.warnings li {
  background-color: #fff4ec;
  border: 2px solid #f9aa60;
  color: #e5774b;
}
.successes li {
  background-color: $success-25;
  border: 2px solid $success;
  color: $success-dark;
}
</style>
