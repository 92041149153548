<template>
  <div id="data-export-modal">
    <CModal
      :show="show"
      :centered="true"
      :close-on-backdrop="false"
      title="Export Data"
      @update:show="close"
    >
      <RSpinnerOverlay :loading="exporting" :opacity="0.7">
        <div>
          Select the format to export the data in:
          <ul>
            <li v-for="format in formats" :key="format.value">
              <RButton variant="link" @click="exportData(format.value)">
                {{ format.label }}
              </RButton>
            </li>
          </ul>
        </div>
      </RSpinnerOverlay>
      <template #footer>
        <CButton color="primary" @click="close">
          Close
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
/**
 * DataExportModal
 *
 * A modal which prompts the user to select one of the
 * following export formats: csv, json, xlsx.
 *
 * Once selected, the provided data will be transformed
 * and automatically downloaded onto the client's system.
 *
 * Events:
 *
 * @close: triggered when the export has completed
 */
import Papa from 'papaparse'
import XLSX from 'xlsx'

import { RButton } from '@/components/buttons'
import { RSpinnerOverlay } from '@/components/spinners'
import { deepCopy, downloadFile, flattenObject, formatDate } from '@/utils'

export default {
  name: 'DataExportModal',
  components: {
    RButton,
    RSpinnerOverlay
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      default: () => []
    },
    fileName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      exporting: false,
      formats: [
        { label: 'CSV', value: 'csv' },
        { label: 'JSON', value: 'json' },
        { label: 'XLSX', value: 'xlsx' }
      ]
    }
  },
  methods: {
    /**
     * Download a file with the exported data.
     *
     * @param {String} format - export format/file extension
     */
    exportData (format) {
      this.exporting = true
      let data = []
      let mimeType = ''

      switch (format) {
        case 'csv':
          data = this.getCSVData()
          mimeType = 'text/csv'
          break
        case 'json':
          data = this.getJSONData()
          mimeType = 'application/json'
          break
        case 'xlsx':
          data = this.getXLSXData()
          mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
      }

      const date = formatDate(new Date(), 'yyyyMMdd')
      const fileName = `${this.fileName}-${date}`

      if (format === 'xlsx') {
        XLSX.writeFile(data, `${fileName}.${format}`)
      } else {
        downloadFile(data, fileName, format, mimeType)
      }

      this.close()
    },
    /**
     * Flatten each object in `data` so that it can be
     * represented on a spreadsheet.
     *
     * @returns {Array}
     */
    formatData () {
      const data = []
      for (const row of this.data) {
        const rowCopy = deepCopy(row)
        data.push(flattenObject(rowCopy))
      }
      return data
    },
    /**
     * Format data as csv.
     *
     * @returns {Array}
     */
    getCSVData () {
      const data = this.formatData()
      const config = {
        header: true,
        skipEmptyLines: true
      }
      const csv = Papa.unparse(data, config)

      return csv
    },
    /**
     * Format data as json.
     *
     * @returns {Array}
     */
    getJSONData () {
      return JSON.stringify(this.data, null, 2)
    },
    /**
     * Format data as xlsx.
     *
     * @returns {Array}
     */
    getXLSXData () {
      const data = this.formatData()
      const worksheet = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet)

      return workbook
    },
    /**
     * Close event to be handled by the parent.
     */
    close () {
      this.exporting = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin-top: 1rem;
}
</style>
