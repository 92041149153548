import { InvoicesAPI } from '@/services/api/resources'
import { formatDate } from '@/utils'
import constants from '@/utils/constants'

/**
 * Calculates the first recurring date for a property,
 * based on its subscription start date.
 *
 * @param {String} date
 * @param {boolean} format
 * @returns {String}
 */
export function getRecurringDate (date, format = true) {
  const startDate = new Date(date)
  const day = startDate.getDate()
  let recurringDate

  if (day <= 15) {
    recurringDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1)
  } else {
    recurringDate = new Date(startDate.getFullYear(), startDate.getMonth() + 2, 1)
  }
  return recurringDate.toISOString()
}
/**
 * Get set of features/products subscriptions.
 *
 * @param {Object} property
 * @returns {Set}
 */
export function getSubscriptionSet (property) {
  const subscriptions = new Set()

  for (const subscription of property.product_subscriptions) {
    if (subscription.status === constants.rooof.Status.INACTIVE) {
      continue
    }
    subscriptions.add(subscription)
  }
  for (const subscription of property.feature_subscriptions) {
    if (subscription.status === constants.rooof.Status.INACTIVE) {
      continue
    }
    subscriptions.add(subscription)
  }
  return subscriptions
}
/**
 * Get discount value in dollars for a subscription
 *
 * @param {Object} subscription
 * @returns {Number}
 */
export function getDiscountValue (subscription) {
  switch (subscription.discount_type) {
    case '$':
      return subscription.discount
    case '%':
      return subscription.rate * (subscription.discount / 100)
  }
}
/**
 * Calculate the total (rate - discount), including
 * the billing frequency.
 *
 * @param {Object} subscription
 * @returns {String} Item price of subscription
 */
export function getItemPrice (subscription) {
  let total = 0
  const monthlyRate = parseFloat(subscription.rate)
  const discount = getDiscountValue(subscription)
  total = monthlyRate * subscription.billing_frequency
  total -= discount * subscription.billing_frequency
  return total ? `${total.toFixed(2)}` : '0.00'
}
/**
 * Calculate the monthly total, including discounts
 *
 * @param {Object} subscription
 * @returns {String} Monthly total cost of subscription
 */
export function getMonthlyTotal (subscription) {
  let total = parseFloat(subscription.rate)
  if (hasDiscount(subscription)) {
    const discount = getDiscountValue(subscription)
    total -= discount
  }
  return total ? `${total.toFixed(2)}` : '0.00'
}
/**
 * Format an APIv2 address object for zoho.
 *
 * @param {(Object|null)} value
 * @returns {Object}
 */
export function formatZohoAddress (value) {
  return {
    attention: value ? value.attention_to : '',
    address: value ? value.street : '',
    street2: '',
    state_code: value ? value.state : '',
    city: value ? value.city : '',
    state: value ? value.state : '',
    zip: value ? value.postal : '',
    country: value ? value.country : ''
  }
}
/**
 * Determine if Product/Feature has an active discount.
 *
 * @param {Object} subscription
 * @returns {Boolean}
 */
export function hasDiscount (subscription) {
  const hasDiscount = Boolean(subscription.discount)
  const expires = subscription.discount_expires
  if (hasDiscount && expires) {
    const today = formatDate(new Date(), 'yyyy-MM-dd')
    return expires >= today
  }
  return hasDiscount
}
/**
 * Send a request to create the zoho customer account.
 *
 * @param {Object} property
 * @param {Object} company
 * @returns {Promise}
 */
export function createZohoAccount (property, company) {
  const payload = {
    contact_name: property.name || property.legal_name,
    company_name: company.human_name,
    payment_terms_label: 'Due on Receipt',
    notes: '',
    billing_address: formatZohoAddress(property.billing_address),
    shipping_address: formatZohoAddress(property.property_address),
    contact_email: property.billing_email,
    currency_code: company.currency_code,
    company_id: company.id,
    property_id: property.id
  }
  return InvoicesAPI.customers.create({ data: payload }, false)
}
