<template>
  <CRow v-if="error || warning || success">
    <CCol>
      <CRow v-if="success">
        <CCol>
          <div class="successes notes">
            Successfully created customer id
          </div>
        </CCol>
      </CRow>
      <CRow v-if="error">
        <CCol>
          <div class="errors notes">
            {{ error }}
          </div>
        </CCol>
      </CRow>
      <CRow v-if="warning">
        <CCol>
          <div class="warnings notes">
            {{ warning }}
          </div>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'AsideNotifications',
  props: {
    error: {
      type: String,
      default: ''
    },
    warning: {
      type: String,
      default: ''
    },
    success: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.errors, .warnings, .successes {
  padding-left: 0;
}
.notes {
  white-space: pre-line;
  border-radius: 5px;
  padding: 5px;
  margin: 2.5px 0;
}

.errors {
  background-color: #FFECEC;
  border: 2px solid #F96060;
  color: #E54B4B;
}
.warnings {
  background-color: #fff4ec;
  border: 2px solid #f9aa60;
  color: #e5774b;
}
.successes {
  background-color: $success-25;
  border: 2px solid $success;
  color: $success-dark;
}
</style>
