<template>
  <div class="r-modal-simple">
    <CModal
      :show="show"
      :centered="true"
      :close-on-backdrop="true"
      @update:show="$emit('close')"
    >
      <template #footer>
        <CButton
          color="info"
          shape="pill"
          size="lg"
          @click="$emit('close')"
        >
          {{ closeButtonText }}
        </CButton>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <div v-if="slot === 'header' && showCloseButton" :key="slot" class="close-modal">
          <CIcon name="md-close" height="20" @click.native="$emit('close')" />
        </div>
        <slot :name="slot" v-bind="scope" />
      </template>
    </CModal>
  </div>
</template>

<script>
/**
 * RModalSimple
 *
 * Wrapper for CoreUI CModal component with some simple
 * styling and default slot content.
 *
 * Useful for displaying simple alert-style messages.
 *
 * Any CModal scoped slots will overwrite those defined in
 * this component. See the docs for more info:
 * https://coreui.io/vue/docs/components/modal.html
 *
 * Events:
 *
 * @close: triggered when the export has completed
 */
export default {
  name: 'RModalSimple',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeButtonText: {
      type: String,
      default: 'OK'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: none;
    padding-top: 1rem;
    padding-bottom: 0;

    .close-modal {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .c-icon {
        cursor: pointer;
      }
    }
  }
  .modal-body {
    text-align: center;
    font-size: 0.95rem;
    padding: 0.75rem 3rem;
  }
  .modal-footer {
    justify-content: center;
    border-top: none;
    padding-bottom: 1rem;

    button {
      padding: 0.25em 1.5em !important;
    }
  }
}
</style>
