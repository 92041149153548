<template>
  <div>
    <CRow align-horizontal="end" class="mb-4">
      <CCol sm="auto" class="d-flex align-items-center">
        <CButton class="ml-2" shape="pill" @click="handleExport">
          <CIcon name="exporting" />
          Export
        </CButton>
        <CButton class="ml-2" shape="pill" @click="handleSyncRecurringInvoices">
          <CIcon name="md-sync" />
          Sync Recurring Invoices
        </CButton>
        <CButton
          class="ml-2"
          shape="pill"
          href="https://books.zoho.com/app#/recurringinvoices/import"
          target="_blank"
        >
          <CIcon name="zoho-books" />
          Upload
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RDataTable
          select
          :items="data"
          :fields="columns"
          :table-props="{ loading, sorter: false }"
          @selection-change="$emit('selection-change', $event)"
        >
          <template #invoiceName="{ item }">
            <td>
              <div class="pb-1">
                <CLink :href="getPropertyLink(item)" target="_blank">
                  {{ (item.company.human_name || item.company.name) }} - {{ item.subscription.property.name }}
                </CLink>
              </div>
              <div class="faded">
                {{ item.subscription.product || item.subscription.feature }}
              </div>
            </td>
          </template>
          <template #startDate="{ item }">
            <td>
              <div class="pb-1">
                {{ formatDate(item.subscription.start_date, 'MMM d, yyyy') }}
              </div>
              <div v-if="item.subscription.start_date" class="faded">
                Recurring: {{ getRecurringDate(item.subscription.start_date) }}
              </div>
            </td>
          </template>
          <template #rate="{ item }">
            <td>
              <div>
                ${{ item.subscription.rate }}
              </div>
            </td>
          </template>
          <template #discount="{ item }">
            <td>
              <div v-if="item.subscription.discount">
                ${{ getDiscountAmount(item.subscription) }}
              </div>
            </td>
          </template>
          <template #totalValue="{ item }">
            <td>
              ${{ getMonthlyTotal(item.subscription) }}
            </td>
          </template>
          <template #currencyCode="{ item }">
            <td>
              {{ item.subscription.currency_code }}
            </td>
          </template>
          <template #setUpFee="{ item }">
            <td>
              {{ item.subscription.setup_fee }}
            </td>
          </template>
          <template #billTo="{ item }">
            <td>
              {{ getBillTo(item) }}
            </td>
          </template>
          <template #edit="{ item }">
            <td>
              <CIcon
                name="md-edit"
                class="icon-button"
                @click.native="$emit('edit-property', item)"
              />
            </td>
          </template>
        </RDataTable>
      </CCol>
    </CRow>
    <DataExportModal
      :show="showDataExportModal"
      :data="exportData"
      :file-name="$route.name"
      @close="showDataExportModal = false"
    />
  </div>
</template>

<script>
import { DataExportModal } from '@/components/modals'
import { RDataTable } from '@/components/tables'
import { InvoicesAPI } from '@/services/api/resources'
import { createAddress, formatAddress, formatDate } from '@/utils'
import { getDiscountValue, getItemPrice, getMonthlyTotal, getRecurringDate } from '@/views/staff/reporting/zoho/_components/utils'

export default {
  name: 'InvoiceTableData',
  components: {
    DataExportModal,
    RDataTable
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      exportData: [],
      showDataExportModal: false,
      columns: [
        { key: 'invoiceName', label: 'Recurring Invoice Name', _style: 'width: 20%' },
        { key: 'startDate', label: 'Start Date', _style: 'width: 16%' },
        { key: 'rate', label: 'Monthly Rate', _style: 'width: 10%' },
        { key: 'discount', label: 'Monthly Discount', _style: 'width: 10%' },
        { key: 'totalValue', label: 'Monthly Item Price', _style: 'width: 10%' },
        { key: 'setUpFee', label: 'Set Up Fee', _style: 'width: 10%' },
        { key: 'currencyCode', label: 'Currency', _style: 'width: 8%' },
        { key: 'billTo', label: 'Bill to', _style: 'width: 8%' },
        { key: 'edit', label: '', _style: 'width: 8%' }
      ]
    }
  },
  computed: {
    tableData () {
      return this.data
    }
  },
  created () {
    this.getDiscountValue = getDiscountValue
    this.getItemPrice = getItemPrice
    this.getMonthlyTotal = getMonthlyTotal
  },
  methods: {
    /**
     * Format an APIv2 date fields.
     *
     * @param {(String|null)} date
     * @param {(String|null)} fmt
     * @returns {String}
     */
    formatDate (date, fmt) {
      if (!date) {
        return 'Unknown'
      }
      return formatDate(date, fmt)
    },
    /**
     * Format billing choice to be displayed using ZOHO terminology
     *
     * @param {Number} billingFrequency
     * @return {String}
     */
    formatZohoBillingFrequency (billingFrequency) {
      switch (billingFrequency) {
        case 1:
          return 'Monthly'
        case 3:
          return '3 months'
        case 6:
          return '6 months'
        case 12:
          return 'Yearly'
        default:
          return 'Unknown'
      }
    },
    /**
     * Get the discount value for the subscription
     *
     * @returns {string}
     */
    getDiscountAmount (subscription) {
      if (!subscription.discount) {
        return ''
      }
      const discount = getDiscountValue(subscription)
      return `${(discount * subscription.billing_frequency).toFixed(2)}`
    },
    /**
     * Calculates the first recurring date for a property,
     * based on its subscription start date.
     *
     * @param {String} date
     * @param {String} fmt
     * @returns {String}
     */
    getRecurringDate (date, fmt = 'MMM d, yyyy') {
      return this.formatDate(getRecurringDate(date), fmt)
    },
    /**
     * Get the invoice type for a property
     *
     * @param {Object} row
     * @returns {String}
     */
    getInvoiceType (row) {
      return row.subscription.zoho_recurring_invoice ? 'Reactivated' : 'New'
    },
    /**
     * Get `Bill to` value to display in table.
     *
     * @param {Object} row
     * @returns {String}
     *
     */
    getBillTo (row) {
      return row.subscription.property.master_invoice ? 'Master' : 'Property'
    },
    /**
     * Formats reactivation date if invoice type is 'Reactivated'
     * New invoices should not have a reactivation date
     *
     * @param {Object} row
     * @returns {String} - reactivation date or blank string
     */
    getReactivationDate (row) {
      if (this.getInvoiceType(row) === 'New') {
        return ''
      }
      return this.formatDate(row.property.start_date, 'MM/dd/yyyy')
    },
    /**
     * Create the Admin link for use with CLink.
     * This should be modified to route link once
     * properties are editable in Portal.
     *
     * @param {Object} row
     * @returns {String}
     */
    getPropertyLink (row) {
      const adminUrl = process.env.VUE_APP_ADMIN_URL
      return `${adminUrl}companies/${row.company.id}/properties/${row.subscription.property.id}`
    },
    /**
     * Initiates the process of synchronizing the Recurring invoices between the Rooof API database and ZOHOs
     */
    async handleSyncRecurringInvoices () {
      const choice = confirm('Are you sure you want to sync the API recurring invoices with ZOHO?')
      if (choice !== true) {
        return
      }
      InvoicesAPI.recurringInvoices.sync().then(
        (response) => {
          if (response.status === 202) {
            alert('✅ API is syncing with ZOHO!\n\nSit tight and check back in 5-10 minutes.')
          }
        }
      ).catch(
        (err) => {
          if (err.response && err.response.status === 429) {
            alert('⏱️ Slow down there! You can only perform this action once per minute.\n\nBut you should at ' +
              'least wait 5-10 minutes for it to finish the first sync')
          } else {
            alert(`⚠️ Failed to initiate sync (${err})`)
          }
        }
      )
    },
    /**
     * Format export data for Zoho
     *
     * @param {Object} data
     * @returns {Object}
     */
    formatExportData (data) {
      const formattedData = []

      for (const row of data) {
        const property = row.subscription.property
        if (!property.billing_address) {
          property.billing_address = createAddress()
        }
        if (!property.property_address) {
          property.property_address = createAddress()
        }
        /* eslint-disable quote-props */
        formattedData.push({
          'Recurring Invoice ID': '',
          'Customer ID': '',
          'Company Name': row.company.human_name || row.company.name || '',
          'Customer Name': property.name || '',
          'Legal Name': property.legal_name || '',
          'Recurring Invoice Name': (row.company.human_name || row.company.name) + ' - ' + property.name,
          'Start Date': this.formatDate(row.subscription.start_date, 'MM/dd/yyyy'),
          'Reactivation Date': this.getReactivationDate(row),
          'Recurring Start Date': this.getRecurringDate(row.subscription.start_date, 'MM/dd/yyyy'),
          'Set-Up Fee': row.subscription.setup_fee,
          'Recurrence Frequency': this.formatZohoBillingFrequency(row.subscription.billing_frequency),
          'Repeat Every': row.subscription.billing_frequency || '',
          'Payment Terms Label': 'Due on Receipt',
          'Currency Code': row.subscription.currency_code,
          'Account': 'Sales',
          'Item Name': row.subscription.product || row.subscription.feature,
          'Item Desc': this.getExportItemDesc(row.subscription),
          'Quantity': row.subscription.billing_frequency || '',
          'Rate': row.subscription.rate,
          'Discount': this.getDiscountAmount(row.subscription),
          'Item Price': this.getItemPrice(row.subscription),
          'Salesperson': row.company.salesperson__name || '',
          'Email Address': property.billing_email || '',
          'Phone': property.phone_contact || '',
          'Billing Att to': property.billing_address.attention_to || '',
          'Billing Address': formatAddress(property.billing_address) || '',
          'Billing Address: Street 1': property.billing_address.street || '',
          'Billing Address: Street 2': property.billing_address.street2 || '',
          'Billing Address: City': property.billing_address.city || '',
          'Billing Address: State': property.billing_address.state || '',
          'Billing Address: Zip': property.billing_address.postal || '',
          'Billing Address: Country': property.billing_address.country || '',
          'Shipping Att To': property.property_address.attention_to || '',
          'Property Address': formatAddress(property.property_address) || '',
          'Property Address: Street 1': property.property_address.street || '',
          'Property Address: Street 2': property.property_address.street2 || '',
          'Property Address: City': property.property_address.city || '',
          'Property Address: State': property.property_address.state || '',
          'Property Address: Zip': property.property_address.postal || '',
          'Property Address: Country': property.property_address.country || '',
          'Stripe': 'TRUE',
          'Billing Method': property.billing_method,
          'Status': row.subscription.status
        })
      }
      return formattedData
    },
    /**
     * onClick handler for export button.
     */
    handleExport () {
      this.showDataExportModal = true
      this.exportData = this.formatExportData(this.tableData)
      return this.exportData
    },
    /**
     * onClose handler for data export modal.
     */
    handleExportModalClose () {
      this.exportData = []
      this.showDataExportModal = false
    },
    /**
     * Determines the Item Description for the export based on the billing frequency
     * @param billingFrequency {Number} - either 1,3,6, or 12
     * @return {String} - Description for the billing frequency
     */
    getExportItemDesc (subscription) {
      let itemDesc = ''
      if (subscription.product && subscription.product.includes('Full Service')) {
        itemDesc = 'Services for '
      } else {
        itemDesc = 'Subscription for '
      }

      switch (subscription.billing_frequency) {
        case 1:
          return itemDesc + '%Month%'
        case 3:
          return itemDesc + '%Month%, %Month+1% and %Month+2%'
        case 6:
          return itemDesc + '%Month% to %Month+6%'
        case 12:
          return itemDesc + '%Year%'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.faded {
  color: $gray-600;
}
.icon-button {
  cursor: pointer;
  &:hover {
    color: $info;
  }
}
</style>
