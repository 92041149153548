<template>
  <CSidebar
    v-if="subscription != null && company != null"
    aside
    overlaid
    :show="show"
    color-scheme="light"
    size="xl"
    class="sidebar"
    @update:show="$emit('update:show', $event)"
  >
    <CSidebarClose @click.native="$emit('update:show', false)" />
    <div class="p-3">
      <CLink :href="getPropertyLink(property, company)">
        <h3>{{ property.name }}</h3>
      </CLink>
      <h6>{{ company.human_name }}</h6>
      <AsideNotifications
        :error="notifications.error"
        :warning="notifications.warning"
        :success="notifications.success"
      />
      <div v-if="company.billing_notes">
        <div class="notes">
          {{ company.billing_notes }}
        </div>
      </div>
      <br>
      <CRow>
        <CCol>
          Customer Name
        </CCol>
      </CRow>
      <CRow class="pb-3">
        <CCol>
          <b>{{ property.name }}</b>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          Legal Name
        </CCol>
      </CRow>
      <CRow class="pb-3">
        <CCol>
          <b>{{ property.legal_name }}</b>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          Zoho Customer ID
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <div v-if="customerId">
            <b>{{ customerId }}</b>
          </div>
          <div v-else>
            <CLink @click="handleCreateCustomerAccount">
              Create Customer Account
            </CLink>
          </div>
        </CCol>
      </CRow>
      <br>
      <CForm>
        <hr>
        <CRow class="pb-2">
          <CCol>
            <h5>Invoicing Details</h5>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <div class="form-group">
              <label>Master Invoice</label>
              <div>
                <CSwitch
                  shape="pill"
                  color="primary"
                  :checked.sync="formData.property.master_invoice"
                />
              </div>
              <span v-if="formData.property.master_invoice" class="color-danger">"Zoho Customer ID" is replaced with property's Invoicing Group “Zoho Customer ID”. Otherwise, select “Create Customer Account”</span>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <label>Zoho Recurring ID</label>
            <CIcon
              v-c-tooltip="{
                content: 'Select “Sync Recurring Invoices” button if “Zoho Recurring ID” does not appear in the search',
                color:'primary',
                placement:'left',
              }"
              name="md-info"
              class="info-icon"
            />
            <RRemoteInputSelect
              v-model="formData.subscription.zoho_recurring_invoice"
              :remote-method="fetchRecurringInvoiceIds"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CAlert color="info">
              Information shown below references product/feature subscription data inputted during onboarding
            </CAlert>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-if="subscription.start_date"
              label="Start Date"
              disabled
              :value.sync="subscription.start_date"
            />
            <CInput
              v-else
              label="Start Date"
              disabled
              value="Unknown"
            />
          </CCol>
          <CCol>
            <CInput
              v-if="subscription.start_date"
              label="Recurring Date"
              disabled
              :value="getRecurringDate(subscription.start_date)"
            />
            <CInput
              v-else
              label="Recurring Date"
              disabled
              value="Unknown"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol class="d-flex pr-0">
            <CInput
              class="monthly"
              label="Monthly Rate"
              disabled
              :value="subscription.rate"
            />
          </CCol>
          <CCol class="d-flex pr-0">
            <CInput
              class="monthly"
              label="Monthly Discount"
              disabled
              :value="getDiscountAmount(subscription)"
            />
          </CCol>
          <CCol class="d-flex">
            <CInput
              class="monthly"
              label="Monthly Item Price"
              disabled
              :value="getMonthlyTotal(subscription)"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              label="Item Name"
              disabled
              :value="getActiveProductSubscription()"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              label="Set Up Fee"
              disabled
              :value="subscription.setup_fee"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <label>Recurrence Frequency</label>
            <CIcon
              v-c-tooltip="{
                content: 'This can modify what is shown under “Billing Frequency” in Admin Portal',
                color:'primary',
                placement:'left',
              }"
              name="md-info"
              class="info-icon"
            />
            <CSelect
              :value.sync="formData.subscription.billing_frequency"
              :options="getZohoBillingChoices()"
            />
          </CCol>
        </CRow>
      </CForm>
      <hr>
      <CRow class="controls">
        <CCol>
          <CButton
            v-c-tooltip="{
              content: 'Saves and removes current row from the list',
              color:'primary',
              placement:'top',
            }"
            color="info"
            variant="outline"
            shape="pill"
            size="sm"
            class="mr-2"
            @click="handleSave(markAsDone=true)"
          >
            Save and remove from list
          </CButton>
        </CCol>
        <CCol class="text-right">
          <CButton
            v-c-tooltip="{
              content: 'When “Zoho Recurring ID” is populated this saves and removes from list, otherwise will save without removing',
              color:'primary',
              placement:'left',
            }"
            color="info"
            shape="pill"
            size="lg"
            @click="handleSave(markAsDone=false)"
          >
            Save
          </CButton>
        </CCol>
      </CRow>
    </div>
  </CSidebar>
</template>

<script>
import RRemoteInputSelect from '@/components/inputs/RRemoteInputSelect'
import { InvoicesAPI, RooofAPI } from '@/services/api/resources'
import { deepCopy, formatDate } from '@/utils'
import AsideNotifications from '@/views/staff/reporting/zoho/_components/AsideNotifications'
import {
  createZohoAccount,
  getDiscountValue,
  getMonthlyTotal,
  getRecurringDate
} from '@/views/staff/reporting/zoho/_components/utils'

export default {
  name: 'PendingInvoicesAside',
  components: {
    AsideNotifications,
    RRemoteInputSelect
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    subscription: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    property: {
      type: Object,
      default: null
    },
    invoicingGroup: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      notifications: {
        warning: '',
        error: '',
        success: false
      },
      formData: {
        subscription: {},
        property: {},
        invoicingGroup: {}
      }
    }
  },
  computed: {
    customerId: {
      get: function () {
        if (this.formData.property.master_invoice) {
          return this.formData.invoicingGroup.zoho_customer_id
        }
        return this.formData.property.zoho_customer_id
      },
      set: function (value) {
        if (this.formData.property.master_invoice) {
          this.formData.invoicingGroup.zoho_customer_id = value
        }
        this.formData.property.zoho_customer_id = value
      }
    }
  },
  watch: {
    subscription (value) {
      const subscriptionData = deepCopy(value)
      this.formData.subscription = {
        billing_frequency: subscriptionData.billing_frequency,
        zoho_recurring_invoice: subscriptionData.zoho_recurring_invoice
      }
    },
    property (value) {
      const propertyData = deepCopy(value)
      this.formData.property = {
        master_invoice: propertyData.master_invoice,
        product_subscriptions: propertyData.product_subscriptions,
        feature_subscriptions: propertyData.feature_subscriptions,
        zoho_customer_id: propertyData.zoho_customer_id
      }
    },
    invoicingGroup (value) {
      const invoicingData = deepCopy(value)
      this.formData.invoicingGroup = {
        zoho_customer_id: invoicingData.zoho_customer_id
      }
    },
    show (value) {
      this.notifications = {
        error: '',
        warning: '',
        success: false
      }
    }
  },
  created () {
    this.getMonthlyTotal = getMonthlyTotal
  },
  methods: {
    async fetchRecurringInvoiceIds (recurringId) {
      const params = new URLSearchParams()
      params.append('recurring_invoice_id', recurringId)
      const response = await RooofAPI.recurringInvoices.list({ params })
      const emptyOption = { label: '', value: null }
      if (!response) {
        return [emptyOption]
      }
      const invoiceIds = response.data.results.map(invoice => {
        return { label: invoice.recurring_invoice_id, value: invoice.recurring_invoice_id }
      })
      return [emptyOption].concat(invoiceIds)
    },
    /**
     * Get billing choices to be displayed.
     *
     *
     * @return {Array}
     */
    getZohoBillingChoices () {
      return [
        { label: 'Monthly', value: 1 },
        { label: '3 months', value: 3 },
        { label: '6 months', value: 6 },
        { label: 'Yearly', value: 12 }
      ]
    },
    /**
     * Calculates the first recurring date for a subscription,
     * based on its subscription start date.
     *
     * @param {String} date
     * @returns {String}
     */
    getRecurringDate (date) {
      return formatDate(getRecurringDate(date), 'yyyy-MM-dd')
    },
    /**
     * Gets the name of the product that this property is actively subscribed to
     *  @return {String}
     */
    getActiveProductSubscription () {
      const productSubscriptions = JSON.parse(JSON.stringify(this.subscription.property.product_subscriptions))
      if (productSubscriptions.length === 1) {
        return productSubscriptions[0].product
      }
      for (const product in productSubscriptions) {
        if (product.end_date === null) {
          return product.product
        }
      }
      return ''
    },
    /**
     * Send an update request to the api with new property and company values.
     * Then closes the aside
     *
     * @param {Boolean} markAsDone - Set the hide_from_recurring_invoices_report to true so the property wont
     *                               show up on this list anymore
     */
    async handleSave (markAsDone = false) {
      this.notifications = {
        error: '',
        warning: '',
        success: false
      }
      const subscriptionCopy = deepCopy(this.subscription)
      const propertyConfig = { id: this.property.id, data: deepCopy(this.formData.property) }
      propertyConfig.data.unit_count = subscriptionCopy.property.unit_count
      const invoicingGroupConfig = { id: this.invoicingGroup.id, data: deepCopy(this.formData.invoicingGroup) }

      // Copy new form values to subscription
      if (this.formData.subscription.zoho_recurring_invoice) {
        subscriptionCopy.zoho_recurring_invoice = this.formData.subscription.zoho_recurring_invoice.value
      }
      subscriptionCopy.billing_frequency = this.formData.subscription.billing_frequency

      // Unsetting zoho fields that are not needed
      if (this.formData.property.master_invoice) {
        propertyConfig.data.zoho_customer_id = null
      } else {
        invoicingGroupConfig.data.zoho_customer_id = null
      }
      if (markAsDone) {
        subscriptionCopy.hide_from_recurring_invoices_report = true
      }
      // Update the subscription with new values
      if (subscriptionCopy.product) {
        const index = propertyConfig.data.product_subscriptions.findIndex(subscription => subscription.id === subscriptionCopy.id)
        propertyConfig.data.product_subscriptions[index] = subscriptionCopy
      } else {
        const index = propertyConfig.data.feature_subscriptions.findIndex(subscription => subscription.id === subscriptionCopy.id)
        propertyConfig.data.feature_subscriptions[index] = subscriptionCopy
      }

      try {
        const propertyResponse = await RooofAPI.properties.partialUpdate(propertyConfig, false)
        subscriptionCopy.property = propertyResponse && propertyResponse.data
        await InvoicesAPI.invoicingGroups.partialUpdate(invoicingGroupConfig, false)
      } catch (err) {
        this.notifications.error = err.toString()
        return
      }

      this.$emit('update:row', {
        subscription: subscriptionCopy,
        company: this.company
      })
      this.handleClose()
    },
    /**
     * Close the aside.
     */
    handleClose () {
      this.$emit('update:show', false)
    },
    /**
     * Get the discount value for the subscription
     *
     * @returns {string}
     */
    getDiscountAmount (subscription) {
      if (!subscription.discount) {
        return '0.00'
      }
      return parseFloat(getDiscountValue(subscription)).toFixed(2)
    },
    /**
     * Create the Admin link for use with CLink.
     *
     * @param {Object} property
     * @param {Object} company
     * @returns {String}
     */
    getPropertyLink (property, company) {
      const adminUrl = process.env.VUE_APP_ADMIN_URL
      return `${adminUrl}companies/${company.id}/properties/${property.id}`
    },
    /**
     * Handle Create Customer Account
     *
     * Refuses to create an account if the id is already set.
     * The ZOHO customer ID can be on either the property or the company depending
     * on if its a master invoice
     */
    async handleCreateCustomerAccount () {
      this.notifications = {
        error: '',
        warning: '',
        success: false
      }
      if (this.customerId) {
        this.notifications.warning = 'Customer ID already exists'
        return
      }
      try {
        const response = await createZohoAccount(this.property, this.company)

        if (response.data.is_duplicate_contact) {
          this.notifications.warning = 'Duplicate contact'
          return
        }

        this.customerId = response.data.contact.contact_id
        this.notifications.success = true
      } catch (err) {
        this.notifications.error = err.toString()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
}

.notes {
  white-space: pre-line;
  background-color: #E2F3FF;
  border: 2px solid #008FFB;
  border-radius: 5px;
  color: #1473E6;
  padding: 5px;
}

.monthly {
  font-size: 0.7rem;
}

.operator {
  margin: auto 0;
  padding: 10px 0 0 15px;
}
.controls {
  margin-bottom: 7em;
}
.info-icon {
  position: absolute;
  top: 6px;
  right: 16px;
  color: $gray-400;
}
.color-danger {
  color: $danger;
}

// Hide the bootstrap validation icons
::v-deep .form-control {
  &.is-valid, &.is-invalid {
    background-image: none;
  }
}
</style>
